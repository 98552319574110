#validateDialog {
  min-width: 450px !important;
}

#success-color {
  color: #07ac2b;
}

#failure-color {
  color: #b30707;
}

#loading-color {
  color: beige;
}

.export-dialog {
  width: 30%;
}
