body {
	color: var(--color-gray-darkest);
	font-family: "Source Sans Pro", sans-serif;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.login-div {
	background-color: #ddd;
	border-radius: 50%;
	height: 1.875rem;
	text-transform: uppercase;
	width: 1.875rem;
}

.fix-footer {
	position: fixed !important;
	left: 0 !important;
	bottom: 0 !important;
	width: 100% !important;
	text-align: center !important;
}

.header-border {
	border-bottom: 1px solid #c4c4c4;
}

.dl-flex-fill-height {
	flex-direction: column;
	flex-grow: 1;
	min-height: 100%;
}

.dl-flex-center {
	justify-content: center;
	align-items: center;
}

.header-text {
	color: #0c0404;
}

.image-align{
	vertical-align: top;
}